import { Injectable } from "@angular/core";
import { BaseService } from "../../core/classes/base.service";
import { Subscription, interval, switchMap, timer } from "rxjs";
import { HttpService } from "../../core/service/http.service";
import { EventService } from "../../core/service/event.service";

@Injectable()
export class NotificationService extends BaseService {

    private pollingSubscription: Subscription;

    notificationServ: Notification[];

    constructor(private http: HttpService) {
        super();
    }

    static NOTIFCATION_POOLING_TIME: 60000;

    override onSignIn(): void {
        super.onSignIn();
        this.pollingSubscription = timer(1000, NotificationService.NOTIFCATION_POOLING_TIME)
            .subscribe(() => {
                this.getNotifications();
            });
    }

    override onSignOut(): void {
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe();
        }
    }

    private getNotifications(): void {
        this.http.Get(false, false, '/user/getNotifications')
            .then(res => {
                this.handleNotifications(res);
            })
            .catch(error => {
                console.error('Error fetching notifications:', error);
            });
    }

    private handleNotifications(notifications: any): void {
        this.notificationServ = notifications.data;
    }

    getAllNotifications() {
        return this.notificationServ
    }

}


export interface Notification {
    message: string
    route: string

}